import React from 'react'
import { useTranslations } from 'next-intl'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import { ArticleTheme, useArticleQuery } from '@nordic-web/gql'
import { FullPageCircleSpinner } from '@nordic-web/ui-components'
import { TypographyTitle } from '@nordic-web/ui-components'
import { NordicApp } from '@/layouts/app'
import { ErrorHandler } from '@/layouts/error-handler'
import { ArticleWrap, StyledModule } from '@/pages/[articleId]/styles'
import type { Page } from '@/pages/types'

const Wrapper = styled.div(({ theme }) => ({
  paddingTop: theme.space(12),
}))

const ArticleContainer = styled.div<{ articleTheme: ArticleTheme }>(({ theme, articleTheme }) => ({
  backgroundColor: articleTheme === ArticleTheme.Light ? theme.color.surface.white90 : theme.color.surface.black20,
  paddingTop: theme.space(1),
  paddingBottom: theme.space(32),
}))

type QueryParameters = {
  articleId: string
}

const Article: Page = () => {
  const router = useRouter()
  const { articleId } = router.query as QueryParameters
  const t = useTranslations()

  const {
    loading: isLoading,
    data,
    called: isCalled,
  } = useArticleQuery({
    skip: !articleId,
    variables: { articleId },
  })

  if (isLoading) {
    return <FullPageCircleSpinner />
  }
  if (!data) {
    return <ErrorHandler statusCode={500} />
  }
  if (isCalled && !isLoading && !data?.article) {
    return <ErrorHandler statusCode={404} />
  }

  const article = data?.article?.html ?? ''
  const header = data?.article?.title
  const date = data?.article?.dateTime?.readableDateLong
  const theme: ArticleTheme = data?.article?.theme ?? ArticleTheme.Light

  const Article = () => (
    <Wrapper>
      <StyledModule>
        <TypographyTitle
          as="h1"
          nwHeadingAppearance="h1"
          nwTextAlign="start"
          nwPreamble={date && t('article__updated_at', { date })}
        >
          {header}
        </TypographyTitle>
      </StyledModule>
      <ArticleContainer articleTheme={theme}>
        <StyledModule>
          <ArticleWrap articleTheme={theme} dangerouslySetInnerHTML={{ __html: article }} />
        </StyledModule>
      </ArticleContainer>
    </Wrapper>
  )

  return <Article />
}

Article.getLayout = (Page, props) => {
  return (
    <NordicApp>
      <Page {...props} />
    </NordicApp>
  )
}

export default Article
