import styled from 'styled-components'
import { ArticleTheme } from '@nordic-web/gql'
import { belowTablet, tablet } from '@nordic-web/ui-styles'
import { PageMargin } from '@/components/scaffolding/page-margin'

type ArticleWrapProps = {
  articleTheme: ArticleTheme
}

export const ArticleWrap = styled.div<ArticleWrapProps>(({ articleTheme, theme }) => {
  return {
    color: articleTheme === ArticleTheme.Light ? theme.color.text.secondaryInverted : theme.color.text.secondary,

    h2: {
      color: articleTheme === ArticleTheme.Light ? theme.color.text.primaryInverted : theme.color.text.primary,
      marginTop: theme.space(6),
      marginBottom: theme.space(1),
      ...theme.typography.title2,

      [tablet]: {
        marginTop: theme.space(10),
        marginBottom: theme.space(4),
      },
    },

    h3: {
      color: articleTheme === ArticleTheme.Light ? theme.color.text.primaryInverted : theme.color.text.primary,
      marginTop: theme.space(4),
      marginBottom: 0,
      ...theme.typography.title3,

      [tablet]: {
        marginTop: theme.space(8),
        marginBottom: theme.space(1),
      },
    },

    p: {
      marginTop: theme.space(4),
      ...theme.typography.body4,

      [tablet]: {
        marginTop: theme.space(6),
      },
    },

    b: {
      fontWeight: 500,
    },

    ul: {
      listStyle: 'initial',
      marginLeft: 17,

      p: {
        marginTop: 8,
        marginBottom: 8,
      },
    },

    td: {
      padding: 8,
      border: '1px solid',
      borderColor: theme.color.surface.black70,

      p: {
        margin: 0,
      },
    },

    table: {
      marginTop: theme.space(4),
      width: '100%',
      borderCollapse: 'collapse',

      [belowTablet]: {
        display: 'block',
        overflowX: 'auto',
      },
    },

    ol: {
      listStylePosition: 'inside',
      listStyle: 'decimal',
      marginLeft: 24,

      li: {
        paddingLeft: 12,
      },

      p: {
        marginTop: 8,
        marginBottom: 8,
      },
    },

    img: {
      maxWidth: '100%',
      height: 'auto',
      marginTop: theme.space(4),

      [tablet]: {
        marginTop: theme.space(6),
      },
    },

    blockquote: {
      background: articleTheme === ArticleTheme.Light ? theme.color.surface.black10 : theme.color.surface.white10,
      color: articleTheme === ArticleTheme.Light ? theme.color.text.primaryInverted : theme.color.text.primary,
      marginTop: theme.space(6),
      paddingTop: 1,
      paddingBottom: theme.space(6),
      paddingLeft: theme.space(8),
      paddingRight: theme.space(8),
      borderBottomLeftRadius: theme.radii.border_radius_large,
      borderTopRightRadius: theme.radii.border_radius_large,
    },

    a: {
      color: articleTheme === ArticleTheme.Light ? theme.color.text.secondaryInverted : theme.color.text.secondary,
      textDecoration: 'underline',
      ...theme.typography.body4,

      '&:hover': {
        color: theme.color.text.highlight,
      },
    },
  }
})

export const StyledModule = styled(PageMargin)(({ theme }) => ({
  maxWidth: 700,
  paddingTop: 0,
  paddingLeft: 16,
  paddingRight: 16,
  marginLeft: 'auto',
  marginRight: 'auto',
  paddingBottom: theme.space(14),
}))
